/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dashboard': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.5 6.5v-3h3v3h-3zM2 3a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1V3zm9.5 3.5v-3h3v3h-3zM10 3a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1V3zm-6.5 8.5v3h3v-3h-3zM3 10a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1H3zm8.5 4.5v-3h3v3h-3zM10 11a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z" _fill="#fff"/>'
  }
})
